import * as React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Blank from "./Blank"
import Login from "./login/Login"
import MainLayout from "./MainLayout"
import Garage from "./garage/Garage"
import RequireAuth from "./RequireAuth"

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Blank />} />
      <Route path="login" element={<Login />} />
      <Route
        path="garage"
        element={
          <RequireAuth>
            <MainLayout selectedListItemId="garage">
              <Garage />
            </MainLayout>
          </RequireAuth>
        }
      />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
)
