import { autorun, makeAutoObservable, runInAction } from "mobx"
import dayjs from "dayjs"
import GarageHuTempRecord from "./GarageHuTempRecord"
import ApiService from "../ApiService"
import LoginStore from "../login/LoginStore"

class GarageHuTempStore {
  private apiService: ApiService

  records?: GarageHuTempRecord[] = undefined
  loading = false
  error: string | undefined = undefined
  hourCount = 6
  fetchSamples = false

  get latestRecord(): GarageHuTempRecord | undefined {
    const records = this.records
    if (records && records.length) {
      return records[records.length - 1]
    }
    return undefined
  }

  get outsideObservationLights(): string | undefined {
    const record = this.latestRecord
    const hum = record?.outside?.hum
    if (hum === undefined) return undefined
    if (hum <= 60) return "GREEN"
    if (hum <= 70) return "YELLOW"
    return "RED"
  }

  get from(): number {
    return dayjs().subtract(this.hourCount, "hours").valueOf()
  }

  constructor(apiService: ApiService, loginStore: LoginStore) {
    this.apiService = apiService
    makeAutoObservable(this)
    autorun(() => {
      if (loginStore.token && !this.fetchSamples && this.hourCount > 0) {
        console.log("Load counts...")
        this.loadCounts()
      }
    })
  }

  async loadCounts() {
    this.loading = true
    this.error = undefined
    try {
      const response = await this.apiService.fetchWithAuth(
        "/api/garage/huTempRecords" + (this.from ? "?from=" + this.from : ""),
        {
          method: "GET",
          credentials: "include",
        }
      )
      if (!response.ok) {
        this.error = `Fehler beim Laden (HTTP ${response.status})`
      } else {
        const records = await response.json()
        runInAction(() => {
          this.records = records
        })
      }
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}
export default GarageHuTempStore
