import { action, makeAutoObservable, runInAction } from "mobx"
import { makePersistable } from "mobx-persist-store"

class LoginStore {
  hydrated = false
  loading = false
  token?: string = undefined

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, { name: "LoginStore", properties: ["token"], storage: window.localStorage }).then(
      action(() => {
        this.hydrated = true
      })
    )
  }

  async login(login: string, password: string) {
    try {
      this.loading = true
      const response = await fetch("/api/login", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ login, password }),
      })
      if (response.ok) {
        const token = await response.text()
        console.log("login:", token)
        await runInAction(async () => {
          this.token = token
        })
      } else {
        throw Error()
      }
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async logout() {
    this.token = undefined
  }
}

export default LoginStore
