import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import RefreshIcon from "@mui/icons-material/Refresh"
import StatusBadge from "../common/StatusBadge"
import TrendChart from "./TrendChart"
import { Card, FormControl, MenuItem, Select } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useGarageHuTempStore } from "../AppStore"
import { runInAction } from "mobx"

const Garage = () => {
  const garageHuTempStore = useGarageHuTempStore()
  const { latestRecord, outsideObservationLights } = garageHuTempStore

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="space-around">
      {latestRecord && (
        <>
          <Grid item sm={4} xs={12}>
            {latestRecord.doorLights === "GREEN" && (
              <StatusBadge color="GREEN" title="Tor" subtitle="Geöffnet halten" />
            )}
            {latestRecord.doorLights === "YELLOW" && (
              <StatusBadge color="YELLOW" title="Tor" subtitle="Kann geöffnet werden" />
            )}
            {latestRecord.doorLights === "RED" && <StatusBadge color="RED" title="Tor" subtitle="Geschlossen halten" />}
          </Grid>
          <Grid item sm={4} xs={12}>
            {latestRecord.observationLights === "GREEN" && (
              <StatusBadge color="GREEN" title="Garage" subtitle="Gute Luftfeuchte" />
            )}
            {latestRecord.observationLights === "YELLOW" && (
              <StatusBadge color="YELLOW" title="Garage" subtitle="Erhöhte Luftfeuchte" />
            )}
            {latestRecord.observationLights === "RED" && (
              <StatusBadge color="RED" title="Garage" subtitle="Luftfeuchte zu hoch" />
            )}
          </Grid>
          <Grid item sm={4} xs={12}>
            {outsideObservationLights === "GREEN" && (
              <StatusBadge color="GREEN" title="Außen" subtitle="Gute Luftfeuchte" />
            )}
            {outsideObservationLights === "YELLOW" && (
              <StatusBadge color="YELLOW" title="Außen" subtitle="Erhöhte Luftfeuchte" />
            )}
            {outsideObservationLights === "RED" && (
              <StatusBadge color="RED" title="Außen" subtitle="Luftfeuchte zu hoch" />
            )}
          </Grid>
        </>
      )}
      {!latestRecord && (
        <Grid item xs={12}>
          <Typography variant="body1">Aktueller Status ist unbekannt</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper sx={{ margin: "auto", overflow: "hidden" }}>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <Toolbar>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <Typography variant="h6" component="div">
                    Verlauf
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl>
                    <Select
                      labelId="day-count-label"
                      id="day-count-select"
                      value={garageHuTempStore.hourCount}
                      onChange={(event) => {
                        runInAction(() => {
                          garageHuTempStore.hourCount = event.target.value as number
                        })
                      }}
                    >
                      <MenuItem value={30 * 24}>30 Tage</MenuItem>
                      <MenuItem value={14 * 24}>14 Tage</MenuItem>
                      <MenuItem value={7 * 24}>7 Tage</MenuItem>
                      <MenuItem value={3 * 24}>3 Tage</MenuItem>
                      <MenuItem value={2 * 24}>2 Tage</MenuItem>
                      <MenuItem value={24}>24 Stunden</MenuItem>
                      <MenuItem value={12}>12 Stunden</MenuItem>
                      <MenuItem value={6}>6 Stunden</MenuItem>
                      <MenuItem value={2}>2 Stunden</MenuItem>
                      <MenuItem value={1}>1 Stunde</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Tooltip title="Reload">
                    <IconButton onClick={() => garageHuTempStore.loadCounts()}>
                      <RefreshIcon color="inherit" sx={{ display: "block" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Card>
            <TrendChart />
          </Card>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default observer(Garage)
