import { runInAction } from "mobx"
import LoginStore from "./login/LoginStore"

class ApiService {
  loginStore: LoginStore

  constructor(loginStore: LoginStore) {
    this.loginStore = loginStore
  }

  async fetchWithAuth(input: RequestInfo, init?: RequestInit): Promise<Response> {
    if (!init) {
      init = {}
    }

    if (!init.headers) {
      init.headers = new Headers()
    } else {
      init.headers = new Headers(init.headers)
    }
    ;(init.headers as Headers).set("Authorization", "Bearer " + this.loginStore.token)
    const response = await fetch(input, init)
    if (response.status === 401 || response.status === 403) {
      runInAction(() => {
        this.loginStore.token = undefined
      })
    }

    return response
  }
}

export default ApiService
