import * as React from "react"
import ReactApexChart from "react-apexcharts"
import { Box } from "@mui/material"
import useDefaultChartOptions from "../common/useDefaultChartOptions"
import merge from "@mui/system/merge"
import { observer } from "mobx-react-lite"
import { useGarageHuTempStore } from "../AppStore"
import { ApexOptions } from "apexcharts"
import dayjs from "dayjs"

const TrendChart = () => {
  const garageHuTempStore = useGarageHuTempStore()

  const records = garageHuTempStore.records ?? []
  const chartData: ApexAxisChartSeries = [
    {
      name: "Relative Feuchte Garage",
      type: "area",
      data: records.map((r) => r.garage?.hum ?? null),
    },
    {
      name: "Relative Feuchte Außen",
      type: "line",
      data: records.map((r) => r.outside?.hum ?? null),
    },
    {
      name: "Temperatur Garage",
      type: "line",
      data: records.map((r) => r.garage?.temp ?? null),
    },
    {
      name: "Temperatur Außen",
      type: "line",
      data: records.map((r) => r.outside?.temp ?? null),
    },
  ]

  const humFormatter = (y: any) => {
    if (y !== undefined) {
      return `${y.toFixed(0)} %`
    }
    return ""
  }

  const tempFormatter = (y: any) => {
    if (y !== undefined) {
      return `${y.toFixed(1)} °C`
    }
    return ""
  }

  const defaultChartOptions = useDefaultChartOptions()
  const chartOptions: ApexOptions = {
    stroke: { width: [2, 2] },
    fill: { type: ["gradient", "solid"] },
    labels: records.map((r) => dayjs(r.time).format("MM.DD. hh:mm")),
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        { formatter: humFormatter },
        { formatter: humFormatter },
        { formatter: tempFormatter },
        { formatter: tempFormatter },
      ],
    },
  }

  return (
    <Box sx={{ p: 3, pb: 1 }} dir="ltr">
      <ReactApexChart series={chartData} options={merge(defaultChartOptions, chartOptions)} height={364} />
    </Box>
  )
}

export default observer(TrendChart)
