import GarageIcon from "@mui/icons-material/Garage"
import WellIcon from "@mui/icons-material/Water"
import * as React from "react"

const navigationCategories = [
  {
    name: "Überwachung",
    children: [
      {
        id: "garage",
        name: "Garage",
        icon: <GarageIcon />,
      },
    ],
  },
  {
    name: "Weiteres",
    children: [
      {
        id: "well",
        name: "Brunnen",
        icon: <WellIcon />,
        onSelect: () => window.open("https://www.brunnen-steenbargkoppel.de", "_blank"),
      },
    ],
  },
]

export default navigationCategories
