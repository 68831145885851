import * as React from "react"
import Divider from "@mui/material/Divider"
import Drawer, { DrawerProps } from "@mui/material/Drawer"
import List from "@mui/material/List"
import Box from "@mui/material/Box"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import HomeIcon from "@mui/icons-material/Home"
import { FunctionComponent } from "react"
import navigationCategories from "./navigationCategories"
import { useNavigate } from "react-router-dom"

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
}

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
}

type Props = DrawerProps & { selectedListItemId: string }

const Navigator: FunctionComponent<Props> = (props) => {
  const { selectedListItemId, ...other } = props

  const navigate = useNavigate()

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}>www.jonasreese.com</ListItem>
        <ListItem sx={{ ...item, ...itemCategory, cursor: "pointer" }} onClick={() => (window.location.href = "/")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Startseite</ListItemText>
        </ListItem>
        {navigationCategories.map(({ name, children }, index) => (
          <Box key={index} sx={{ bgcolor: "#101F33" }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>{name}</ListItemText>
            </ListItem>
            {children.map(({ id, name, icon, onSelect }) => (
              <ListItem disablePadding key={id}>
                <ListItemButton
                  selected={id === selectedListItemId}
                  sx={item}
                  onClick={() => {
                    if (onSelect) {
                      onSelect()
                    } else {
                      navigate("/" + id)
                    }
                    other.onClose?.({}, "backdropClick")
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{name}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  )
}

export default Navigator
