import * as React from "react"
import { FunctionComponent } from "react"
import AppBar from "@mui/material/AppBar"
import Avatar from "@mui/material/Avatar"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"

interface HeaderProps {
  title: string
  onDrawerToggle: () => void
}

const Header: FunctionComponent<HeaderProps> = (props) => {
  const { title, onDrawerToggle } = props

  return (
    <React.Fragment>
      <AppBar component="div" color="primary" position="sticky" elevation={0} sx={{ padding: 0.5, zIndex: 0 }}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid sx={{ display: { md: "none", sm: "block", xs: "block" } }} item>
              <IconButton color="inherit" aria-label="open drawer" onClick={onDrawerToggle} edge="start">
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {title}
              </Typography>
            </Grid>
            {/*<Grid item>*/}
            {/*  <Button sx={{ borderColor: lightColor }} variant="outlined" color="inherit" size="small">*/}
            {/*    Web setup*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
                <Avatar>J</Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/*<AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>*/}
      {/*  <Tabs value={0} textColor="inherit">*/}
      {/*    <Tab label="Users" />*/}
      {/*    <Tab label="Sign-in method" />*/}
      {/*    <Tab label="Templates" />*/}
      {/*    <Tab label="Usage" />*/}
      {/*  </Tabs>*/}
      {/*</AppBar>*/}
    </React.Fragment>
  )
}

export default Header
