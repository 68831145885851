import * as React from "react"
import { Card } from "@mui/material"
import Typography from "@mui/material/Typography"
import { FunctionComponent } from "react"

interface Props {
  color: string
  title: string
  subtitle: string
}

const StatusBadge: FunctionComponent<Props> = (props) => {
  const { color, title, subtitle } = props
  let backgroundColor: string | undefined
  if (color === "GREEN") backgroundColor = "#c8facd"
  else if (color === "YELLOW") backgroundColor = "#fff7cd"
  else if (color === "RED") backgroundColor = "#ffe7d9"
  else backgroundColor = undefined

  return (
    <Card
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor,
        minHeight: 130,
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 700, lineHeight: 1.5, opacity: 0.72 }}>
        {title}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {subtitle}
      </Typography>
    </Card>
  )
}

export default StatusBadge
