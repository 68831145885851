import * as React from "react"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  createTheme,
  CssBaseline,
  FormControlLabel,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material"
import { Copyright } from "@mui/icons-material"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useLoginStore } from "../AppStore"

const theme = createTheme()

const Login = () => {
  const loginStore = useLoginStore()
  const navigate = useNavigate()
  const location = useLocation()

  const from = (location.state as any)?.from?.pathname || "/"

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const login = formData.get("login") as string
    const password = formData.get("password") as string

    try {
      await loginStore.login(login, password)
      navigate(from, { replace: true })
    } catch (e) {
      alert("Login fehlgeschlagen, bitte erneut probieren")
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Anmelden
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Email"
              name="login"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Eingeloggt bleiben" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Anmelden
            </Button>
            <Grid container>
              <Grid item xs>
                {" "}
              </Grid>
              <Grid item>
                <Box sx={{ display: "flex" }}>
                  <Copyright /> <span>www.jonasreese.com</span>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default observer(Login)
