import React, { useContext } from "react"
import LoginStore from "./login/LoginStore"
import ApiService from "./ApiService"
import GarageHuTempStore from "./garage/GarageHuTempStore"

class AppStore {
  loginStore: LoginStore
  apiService: ApiService
  garageHuTempStore: GarageHuTempStore

  constructor() {
    this.loginStore = new LoginStore()
    this.apiService = new ApiService(this.loginStore)
    this.garageHuTempStore = new GarageHuTempStore(this.apiService, this.loginStore)
  }
}

const appStore = new AppStore()
;(window as any).__APP_STORE = appStore
export const AppStoreContext = React.createContext<AppStore>(appStore)

export const useLoginStore = () => {
  return useContext(AppStoreContext).loginStore
}

export const useGarageHuTempStore = () => {
  return useContext(AppStoreContext).garageHuTempStore
}
